import axios from "axios";

let apiUrl;
let documentUrl;
let baseUrl;

switch (process.env.VUE_APP_MODE) {
  case "local":
    apiUrl = "http://10.2.98.70:8088/";
    documentUrl = `http://phrdoc.aijiayi.com/web/#/1?page_id=1`;
    baseUrl = "http://devorg.phr.aijiayi.com/#/login";
    break;
  case "development":
    apiUrl = "http://api-dev.aijiayi.com";
    documentUrl = `http://phrdoc.aijiayi.com/web/#/1?page_id=1`;
    baseUrl = "http://devorg.phr.aijiayi.com/#/login";
    break;
  case "test":
    apiUrl = "http://api-dev.aijiayi.com";
    documentUrl = `http://phrdoc.aijiayi.com/web/#/1?page_id=1`;
    baseUrl = "http://devorg.phr.aijiayi.com/#/login";
    break;
  case "preProduction":
    apiUrl = "http://pre.phrapi.aijiayi.com";
    documentUrl = "http://phrdoc.aijiayi.com/web/#/1?page_id=1";
    baseUrl = "http://preorg.phr.aijiayi.com/#/index";
    break;
  case "production":
    apiUrl = "https://api.aijiayi.com";
    documentUrl = "http://phrdoc.aijiayi.com/web/#/1?page_id=1";
    baseUrl = "http://phrorg.aijiayi.com/#/index";
    break;
  default:
    apiUrl = "http://10.2.144.66:8080/";
    documentUrl = `http://phrdoc.aijiayi.com/web/#/1?page_id=1`;
    baseUrl = "http://devorg.phr.aijiayi.com/#/login";
    break;
}

const http = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 7000
});

http.interceptors.response.use(
  response => {
    if (response.data.code === "200") {
      return response.data;
    } else {
      alert(response.data.msg);
    }
  },
  error => {
    // alert('请求失败')
    return Promise.reject(error);
  }
);

export { baseUrl, documentUrl, http };
