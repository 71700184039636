const state = {
  doctorGuide: '',
  doctorName: ''
}

const mutations = {
  setDoctorGuide(result, params) {
    return (result.doctorGuide = params)
  },
  removeDoctorGuide(result) {
    return (result.doctorGuide = '')
  },
  setDoctorName(result, paramas) {
    return (result.doctorName = paramas)
  },
  removeDoctorName(result) {
    return (result.doctorName = '')
  }
}

export default {
  state,
  mutations
}
