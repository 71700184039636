<template>
  <div class="header">
    <swiper :options="swiperOption" ref="mySwiper">
      <!-- slides -->
      <swiper-slide >
        <img src="@src/assets/img/banner.png" />
<!--        <section class="banner1">-->
<!--          <p class="b">实时监测医疗数据</p>-->
<!--          <p class="b">全面守护居民健康</p>-->
<!--          <p class="s">MONITOR MEDICAL DATA IN REAL TIME</p>-->
<!--          <p class="s">PROTECT THE HEALTH OF ALL RESIDENTS</p>-->
<!--        </section>-->
      </swiper-slide>
      <swiper-slide>
        <img src="@src/assets/img/banner2.png" />
<!--        <section class="banner2">-->
<!--          <p class="b">爱家医</p>-->
<!--          <p class="b1">千万用户共同的选择</p>-->
<!--          <p class="s s1">A common choice for millions of users</p>-->
<!--        </section>-->
      </swiper-slide>

      <!-- Optional controls -->
      <div class="swiper-pagination"  slot="pagination"></div>
    </swiper>
    <ul class="menu">
      <li class="home"><a>爱家医</a></li>
      <li class="index"><a href="./">首页</a></li>
      <li @click="goFloor('s2')"><a>案例</a></li>
      <li><a :href="documentUrl" target="_blank">文档</a></li>
      <li class="login"><a :href="baseUrl + '/#/index'">登录</a></li>
    </ul>
  </div>
</template>
<script>
  import 'swiper/dist/css/swiper.css'
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import {baseUrl, documentUrl} from '@src/config.js';
  export default {
    components: {
      swiper,
      swiperSlide
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.swiper
      }
    },
    mounted() {
      this.baseUrl = baseUrl;
      this.documentUrl = documentUrl;
      // current swiper instance
      // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
      //console.log('this is current swiper instance object', this.swiper)
      //this.swiper.slideTo(0, 1000, false)
    },
    data() {
      return {
        baseUrl: '',
        documentUrl: '',
        swiperOption: {
          autoplay: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        }
      }
    },
    methods: {
      goFloor(tag) {
        let top = this.$parent.$refs[tag].$el.offsetTop;
        const currentY = document.documentElement.scrollTop || document.body.scrollTop
        this.scrollAnimation(currentY, top)
      },
      scrollAnimation(currentY, targetY) {
        // 获取当前位置方法
        // const currentY = document.documentElement.scrollTop || document.body.scrollTop

        // 计算需要移动的距离
        let needScrollTop = targetY - currentY
        let _currentY = currentY
        setTimeout(() => {
          // 一次调用滑动帧数，每次调用会不一样
          const dist = Math.ceil(needScrollTop / 10)
          _currentY += dist
          window.scrollTo(_currentY, currentY)
          // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
          if (needScrollTop > 10 || needScrollTop < -10) {
            this.scrollAnimation(_currentY, targetY)
          } else {
            window.scrollTo(_currentY, targetY)
          }
        }, 1)
      },
    }
  }
</script>
<style lang="scss">
  .swiper-button-prev {
    width: .7rem;
    height: .7rem;
    left: .5rem;
    background: url('../assets/img/left.png') no-repeat;
    background-size: 100% 100%;
  }
  .swiper-button-next {
    width: .7rem;
    height: .7rem;
    right: .5rem;
    background: url('../assets/img/right.png') no-repeat;
    background-size: 100% 100%;
  }
  .header {
    width: 100%;
    position: relative;
    background: #00C6AE;
    height: 7rem;
    color: #fff;
    .swiper-pagination-bullet-active {
      background: #fff;
    }
    .menu {
      position: absolute;
      left: 3.6rem;
      top: 0;
      z-index: 10;
      font-size: .24rem;
      li {
        float: left;
        list-style: none;
        margin-top: .43rem;
        margin-right: 1.1rem;
        cursor: pointer;
        a {
          color: #fff;
          text-decoration: none;
        }
        &.index {
          margin-left: 2.8rem;
        }
        &.home:before {
           content: '';
           width: .46rem;
           height: .4rem;
           display: inline-block;
           vertical-align: middle;
           background: url('../assets/img/logo.png') no-repeat;
           background-size: 100% 100%;
           margin-right: 10px;
        }
        &.login:before {
          content: '';
          width: .22rem;
          height: .24rem;
          display: inline-block;
          vertical-align: middle;
          background: url('../assets/img/denglu.png') no-repeat;
          background-size: 100% 100%;
          margin-right: 10px;
          margin-top: -.05rem;
        }
      }
    }
    .swiper-slide {
      width: 100%;
      position: relative;
      section.banner1 {
        position: absolute;
        left: 11rem;
        top: 3rem;
        width: 4rem;
        p.b {
          width: 100%;
          font-size:.50rem;
          text-align: left;
          text-align-last: justify;
        }

        p.s {
          width: 100%;
          text-align: right;
          font-size: .12rem;
          color:rgba(125,231,217,1);
        }
        .access {
          width:1.78rem;
          height:.54rem;
          background:rgba(255,255,255,1);
          border-radius:.54rem;
          font-size: .24px;
          color:rgba(0,198,174,1);
          float: right;
          line-height: .54rem;
          margin-top: 30px;
        }
      }
      section.banner2 {
        position: absolute;
        left: 3.6rem;
        top: 3rem;
        p.b {
          font-size:.55rem;
          text-align: left;
          letter-spacing: .2rem;
        }
        p.b1 {
          font-size:.4rem;
          text-align: left;
          letter-spacing: .1rem;
        }
        p.s {
          width: 100%;
          font-size: .12rem;
          text-align: left;
          color:rgba(125,231,217,1);
          margin-top: .1rem;
        }
        .access {
          width:1.78rem;
          height:.54rem;
          background:rgba(255,255,255,1);
          border-radius:.54rem;
          font-size: .24px;
          color:rgba(0,198,174,1);
          display: block;
          line-height: .54rem;
          margin-top: 30px;
        }
      }
      img {
        width: 100%;
      }
    }
  }
</style>
