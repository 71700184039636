import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Toasted from 'vue-toasted'
import { apiUrl } from './config'
import store from '@src/store'
Vue.use(Toasted, {
  position: 'top-left',
  duration: 1500
})
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  store,
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
