<template>
  <div class="solution">
    <h1>解决方案</h1>
    <div class="tab">
      <div class="tab_header">
        <ul class="tab_menu">
          <li v-for="(item,index) in items"
              :key="index"
              :class="item.active ? 'active': ''"
              @click="changeTab(index)">{{item.name}}</li>
        </ul>
      </div>
      <div class="tab_content tab_content_1"
           v-show="items[0].active">
        <div class="leftImg">
          <img src="../assets/img/solution-device.png"
               alt="">
        </div>
        <div class="content">
          <div class="t">
            <div class="title">健康设备购买</div>
            <div class="text">
              平台覆盖主流品牌20余类健康设备，更多类型设备持续更新，购买设备即可享受设备与数据的全套服务，接入成本低，集成速度快
            </div>
            <div class="btn"
                 @click="device1Action">
              查看详情 <img src="../assets/img/ringht-arrow.png"
                   alt="">
            </div>
          </div>
          <div class="t">
            <div class="title">健康设备接入</div>
            <div class="text">
              成熟的健康平台管理模式，多渠道的数据来源，设备生产商的设备一经接入，即可持续获取精确数据。
            </div>
            <div class="btn"
                 @click="device2Action">
              查看详情 <img src="../assets/img/ringht-arrow.png"
                   alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="tab_content tab_content_2"
           v-show="items[1].active">
        <div class="leftImg">
          <img src="../assets/img/solution-archive.png"
               alt="">
        </div>
        <div class="content">
          <div class="t">
            <div class="title">健康档案服务</div>
            <div class="text">
              通过健康设备测量或API接口，上传健康数据到平台，平台进行数据的智能分类汇总，提供更多健康增值服务。同时，通过API接口，可获得居民详细的健康数据，更有多渠道数据支持，健康档案一手在握。
            </div>
            <div class="btn"
                 @click="archiveAction">
              查看详情 <img src="../assets/img/ringht-arrow.png"
                   alt="">
            </div>
          </div>
          <div class="i3">
            <div class="i3-item">
              <img src="../assets/img/solution-archive-1.png"
                   alt="">
              <div>健康基本信息</div>
            </div>
            <div class="i3-item">
              <img src="../assets/img/solution-archive-2.png"
                   alt="">
              <div>健康指标</div>
            </div>
            <div class="i3-item">
              <img src="../assets/img/solution-archive-3.png"
                   alt="">
              <div>诊疗信息</div>
            </div>

          </div>
        </div>
      </div>
      <div class="tab_content tab_content_3"
           v-show="items[2].active">
        <div class="leftImg">
          <img src="../assets/img/solution-report.png"
               alt="">
        </div>

        <div class="content">
          <div class="t">
            <div class="title">健康报告服务</div>
            <div class="text">
              通过健康数据分析引擎，智能分析居民健康数据，推送各类型健康报告服务，支持客户进行健康管理服务。
            </div>
            <div class="btn"
                 @click="reportAction">
              查看详情 <img src="../assets/img/ringht-arrow.png"
                   alt="">
            </div>
          </div>
          <div class="i3">
            <div class="i3-item">
              <img src="../assets/img/solution-report-1.png"
                   alt="">
              <div>健康基本信息</div>
            </div>
            <div class="i3-item">
              <img src="../assets/img/solution-report-2.png"
                   alt="">
              <div>健康指标</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_content tab_content_4"
           v-show="items[3].active">
        <div class="leftImg">
          <img src="../assets/img/solution-rate.png"
               alt="">
        </div>
        <div class="content">
          <div class="t">
            <div class="title">健康评估服务</div>
            <div class="text">
              成熟、权威的评估模型，评估流程一键接入，评估结果多方式查看。
            </div>
            <div class="btn"
                 @click="rateAction">
              查看详情 <img src="../assets/img/ringht-arrow.png"
                   alt="">
            </div>
          </div>
          <div class="i3">
            <div class="i3-item">
              <img src="../assets/img/solution-rate-1.png"
                   alt="">
              <div>健康基本信息</div>
            </div>
            <div class="i3-item">
              <img src="../assets/img/solution-report-2.png"
                   alt="">
              <div>健康指标</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Solution",
  data () {
    return {
      tabShow: true,
      items: [
        {
          name: "健康设备服务",
          active: true,
        },
        {
          name: "健康档案服务",
          active: false,
        },
        {
          name: "健康报告服务",
          active: false,
        }, {
          name: "健康评估服务",
          active: false,
        }
      ]
    }
  },
  mounted () {
    let index = sessionStorage.getItem("solution_index")
    this.changeTab(index ? index : 0)
  },
  methods: {
    changeTab (index) {
      sessionStorage.setItem("solution_index", index)
      this.items = this.items.map((item, i) => {
        let t = item
        t.active = index == i
        return t
      })
    },
    device1Action () {
      this.detailAction('1')
    },
    device2Action () {
      this.detailAction('1')
    },
    archiveAction () {
      this.detailAction('2')
    },
    reportAction () {
      this.detailAction('3')
    },
    rateAction () {
      this.detailAction('4')
    },
    detailAction (id) {
      // let routeData = this.$router.resolve(
      //   { path: '/SolutionDetail', query: { id: id } }
      // );
      // window.open(routeData.href, '_blank');
      this.$router.push({ path: '/SolutionDetail', query: { id: id } });
    }
  }
}
</script>

<style lang="scss">
.solution {
  overflow: hidden;
  h1 {
    font-size: 0.3rem;
    font-weight: 500;
    color: rgba(89, 89, 89, 1);
    margin-top: 0.6rem;
  }
  .tab {
    width: 100%;
    .tab_header {
      width: 100%;
      border-bottom: 1px solid rgba(151, 151, 151, 0.25);
      .tab_menu {
        display: flex;
        justify-content: space-between;
        width: 12rem;
        margin: 0.4rem auto 0;
        li {
          list-style: none;
          width: 1.7rem;
          border-bottom: 2px solid transparent;
          font-size: 0.28rem;
          font-weight: 500;
          color: rgba(208, 208, 208, 1);
          height: 0.5rem;
          cursor: pointer;
        }
        li.active {
          color: rgba(0, 198, 174, 1);
          border-bottom: 2px solid rgba(0, 198, 174, 1);
        }
      }
    }
    .tab_content {
      display: flex;
      flex-direction: row;
      width: 12rem;
      margin: 0.3rem auto 0;
      margin-top: 0.6rem;
      padding-bottom: 0.6rem;
      .leftImg {
        img {
          width: 5rem;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .t {
          margin: 0 0 0 0.4rem;
          text-align: left;
          .title {
            font-size: 0.24rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 400;
            color: rgba(89, 89, 89, 1);
          }
          .text {
            margin: 0.2rem 0;
            font-size: 0.18rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(99, 99, 99, 1);
          }
          .btn {
            font-size: 0.22rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 198, 174, 1);
            img {
              width: 0.32rem;
            }
          }
        }
        .i3 {
          display: flex;
          flex-direction: row;
          font-size: 0.18rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(99, 99, 99, 1);
          .i3-item {
            display: flex;
            flex-direction: column;
            margin: 0 0 0 0.45rem;
            img {
              width: 1.82rem;
            }
          }
        }
      }
    }
  }
}
</style>
