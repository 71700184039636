<template>
  <div class="access">
    <h1>接入流程</h1>
    <div class="tab">
      <div class="tab_header">
        <ul class="tab_menu">
          <li :class="tabStatus[0] &&  'active'" @click="changeTab(0)">GSM传输</li>
          <li :class="tabStatus[1] && 'active'"  @click="changeTab(1)">蓝牙传输</li>
          <li :class="tabStatus[2] && 'active'"  @click="changeTab(2)">API对接</li>
        </ul>
      </div>
      <div class="tab_content" v-show="tabStatus[0]">
          <div class="left">
              <div class="step">
                <i></i>
                <span class="num">01</span>
                <span class="text">入驻平台，获取渠道key值</span>
              </div>
              <div class="step">
                <i class="b"></i>
                <span class="num">02</span>
                <div class="step2">
                  <span class="text">硬件设备烧录PHR云端存储地址</span>
                  <a class="right link" @click="showLayer('layer1')">查看已支持的设备</a>
                </div>
              </div>
            <div class="step">
              <i class="c"></i>
              <span class="num">03</span>
              <span class="text">开始使用:</span>
            </div>
            <p>
              硬件设备与用户的绑定关系带渠道key值上传至平台<br/>
              支持日常检测数据正常上传和调用<br/>
              定期推送健康报告

            </p>
          </div>

      </div>
      <div class="tab_content tab_content_2" v-show="tabStatus[1]">
        <div class="left">
          <div class="step">
            <i></i>
            <span class="num">01</span>
            <span class="text">入驻平台，获取渠道key值</span>
          </div>
          <div class="step">
            <i class="b"></i>
            <span class="num">02</span>
           <div class="step2">
             <span class="text">获取设备对应SDK</span>
             <a class="right link link1" @click="showLayer('layer2')">查看已支持的设备</a>
           </div>
          </div>
          <div class="step">
            <i class="c"></i>
            <span class="num">03</span>
            <span class="text">开始使用:</span>
          </div>
          <p>
            支持日常检测数据正常上传和调用 <br/>
            定期推送健康报告
          </p>
        </div>

      </div>
      <div class="tab_content tab_content_3" v-show="tabStatus[2]">
        <div class="left">
          <div class="step">
            <i></i>
            <span class="num">01</span>
            <span class="text">入驻平台，获取渠道key值</span>
          </div>
          <div class="step">
            <i class="b"></i>
            <span class="num">02</span>
            <div class="step2">
              <span class="text">服务端接口开发</span>
              <a class="right link link1" :href="documentUrl" target="_blank">查看接口文档详情</a>
            </div>
          </div>
          <div class="step">
            <i class="c"></i>
            <span class="num">03</span>
            <span class="text">开始使用:</span>
          </div>
          <p>
            支持日常检测数据正常上传和调用 <br/>
            定期推送健康报告
          </p>
        </div>

      </div>
    </div>
    <div class="layer" v-if="layer1">
      <div class="inner">
          <div class="top">
            <span>已支持设备</span>
            <i @click="hideLayer('layer1')"></i>
          </div>
          <div class="content">
              <div class="icon animated finite bounceInUp icon1">
                <img src="../assets/img/yq1.png"/>
                <span>脉搏波血压计<br/>
                     RBP-9801</span>
              </div>
            <div class="icon animated finite bounceInUp icon2">
              <img src="../assets/img/yq2.png"/>
              <span>爱奥乐血糖仪<br/>
                    G-777G</span>
            </div>
            <div class="icon animated finite bounceInUp icon3">
              <img src="../assets/img/yq0.png"/>
              <span>更多设备接入中 <br/>
                     ......</span>
            </div>
          </div>
      </div>
    </div>
    <div class="layer" v-if="layer2">
      <div class="inner">
        <div class="top">
          <span>已支持设备</span>
          <i @click="hideLayer('layer2')"></i>
        </div>
        <div class="content content2">
          <div class="icon animated finite bounceInUp icon1">
            <img src="../assets/img/yq3.png"/>
            <span>康泰便携式心电计<br/>
                     PM10</span>
          </div>
          <div class="icon animated finite bounceInUp icon2">
            <img src="../assets/img/yq4.png"/>
            <span>图层三诺血糖仪<br/>
                   (安稳+Air)</span>
          </div>
          <div class="icon animated finite bounceInUp icon3">
            <img src="../assets/img/yq5.png"/>
            <span>康泰脉搏血氧仪 <br/>
                     CMS50D-BT</span>
          </div>
          <div class="icon animated finite bounceInUp icon4">
            <img src="../assets/img/yq6.png"/>
            <span>康泰电子血压计 <br/>
                    CONTEC08C</span>
          </div>
          <div class="icon animated finite bounceInUp icon5">
            <img src="../assets/img/yq7.png"/>
            <span>和泰达红外线体温计 <br/>
                      (HTD8808E)</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {documentUrl} from '@src/config.js';
    export default {
        name: "Access",
        data() {
          return {
            documentUrl:'',
            tabStatus: [true, false, false],
            layer1: false,
            layer2: false
          }
        },
        methods: {
          showLayer(tag) {
            this[tag] =true;
          },
          hideLayer(tag) {
            this[tag] = false;
          },
          changeTab(index) {
            this.tabStatus = [false, false, false];
            this.$set(this.tabStatus, index, true);
          }
        },
        mounted() {
          this.documentUrl = documentUrl;
          // current swiper instance
          // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
          //console.log('this is current swiper instance object', this.swiper)
          //this.swiper.slideTo(0, 1000, false)
        }
    }
</script>

<style lang="scss">
  .access {
    background:rgba(235,235,235,.29);
    overflow: hidden;
    position: relative;
    height: 8rem;
    .layer {
      width: 100%;
      height: 100%;
      top:0;
      left: 0;
      position: absolute;
      background:rgba(0,0,0,.2);
      .inner {
        width:12rem;
        height:5.2rem;
        background:rgba(252,252,252,1);
        border-radius:.1rem;
        margin: .9rem auto 0;
        padding: 0 .5rem;
        box-sizing: border-box;
        .top {
          height: .93rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: .3rem;
          font-weight:500;
          color:rgba(208,208,208,1);
          border-bottom: 1px solid #D0D0D0;
          i {
            display: inline-block;
            width: .4rem;
            height: .4rem;
            background: url('../assets/img/shanchu.png') no-repeat;
            background-size: 100% 100%;
          }
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          width: 11rem;
          margin: .3rem auto 0;
          padding-bottom: .6rem;
          .icon {
            width: 2.5rem;
            margin-top: .6rem;
            &.icon1 {
              animation-duration: .7s;
              animation-delay: .2s;
            }
            &.icon2 {
              animation-duration: .7s;
              animation-delay: .3s;
            }
            &.icon3 {
              animation-duration: .7s;
              animation-delay: .4s;
            }
            &.icon4 {
              animation-duration: .7s;
              animation-delay: .5s;
            }
            &.icon5 {
              animation-duration: .7s;
              animation-delay: .6s;
            }
            img {
              width: 1.4rem;
              height: 1.4rem;
              display: block;
              margin: 0 auto;
            }
            span {
              font-size:.22rem;
              font-weight:400;
              color:rgba(120,120,120,1)
            }
          }
        }
        .content2 {
          width: 10rem;
          .icon {
            width: 2rem;
          }
        }
      }
    }
  h1 {
    font-size:.3rem;
    font-weight:500;
    color:rgba(89,89,89,1);
    margin-top: .6rem;
  }
  .tab {
    width: 100%;
  .tab_header {
    width: 100%;
    border-bottom: 1px solid rgba(151,151,151,.25);
  .tab_menu {
    display: flex;
    justify-content: space-between;
    width: 9rem;
    margin: .4rem auto 0;
      li {
        list-style: none;
        width: 2rem;
        border-bottom: 2px solid transparent;
        font-size: .28rem;
        font-weight:500;
        color:rgba(208,208,208,1);
        height: .5rem;
        cursor: pointer;
      }
      li.active {
        color: rgba(0,198,174,1);
        border-bottom: 2px solid rgba(0,198,174,1);
      }
  }
  }
  .tab_content {
    display: flex;
    width: 10rem;
    margin: 0 auto;
    padding-top: 1rem;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    .left {
      width: 100%;
      .step {
        display: flex;
        align-items: center;
        margin-bottom: .3rem;
        .step2 {
          flex: 1;
          text-align: left;
          display: flex;
          justify-content: space-between;
        }
         i {
           width: .9rem;
           height: .9rem;
           background: url('../assets/img/a.png') no-repeat;
           background-size: 100% 100%;
           display: inline-block;
           &.b {
             background: url('../assets/img/b.png') no-repeat;
             background-size: 100% 100%;
           }
           &.c {
             background: url('../assets/img/c.png') no-repeat;
             background-size: 100% 100%;
           }
         }

        .num {
          font-size:.34rem;
          color:rgba(0,198,174,1);
          margin: 0 .2rem 0 .7rem;
        }
        .text {
          font-size:.22rem;
          color:rgba(89,89,89,1);
        }
      }
      p {
        font-size: .18rem;
        font-weight:400;
        color:rgba(99,99,99,1);
        text-align: left;
        margin: -.5rem 0 0 2.17rem;
      }
    }
    .right {
      font-size:.22rem;
      font-weight:500;
      color:rgba(0,198,174,1)!important;
      cursor: pointer;
      text-decoration: none;
    }
  }
  }
  }
</style>
