<template>
  <div class="scene" ref="scene">
    <h1>使用场景</h1>
    <img src="../assets/img/scene.jpg">
  </div>
</template>

<script>
    export default {
        name: "Scene"
    }
</script>

<style lang="scss">
  .scene {
    width: 100%;
    height: 7rem;
    position: relative;
    overflow: hidden;
    h1 {
      font-size:.3rem;
      margin-top: .6rem;
      color:rgba(255,255,255,1);
      line-height:.42rem;
      font-weight: 500;
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
</style>
