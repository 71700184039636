import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

export default new Router({
  mode: "history",

  routes: [
    {
      path: "/",
      name: "home",
      component: () => import(/* webpackChunkName: "home" */ "./views/Home.vue")
    },
    {
      path: "/SolutionDetail",
      name: "SolutionDetail",
      component: () =>
        import(
          /* webpackChunkName: "SolutionDetail" */ "./views/SolutionDetail.vue"
        )
    },
    {
      path: "/medical",
      name: "中医首页",
      component: () =>
        import(/* webpackChunkName: "medical" */ "./views/MedicalIndex.vue")
    },
    {
      path: "/medicaldetail",
      name: "中医详情",
      component: () =>
        import(
          /* webpackChunkName: "medicaldetail" */ "./views/MedicalDetail.vue"
        )
    },
    {
      path: "/medicalbegin",
      name: "测试开始",
      component: () =>
        import(/* webpackChunkName: "medicalbegin" */ "./views/MedicalOpen.vue")
    },
    {
      path: "/medicaltest",
      name: "中医测试",
      component: () =>
        import(/* webpackChunkName: "medicaltest" */ "./views/MedicalTest.vue")
    },
    {
      path: "/medicalresult",
      name: "中医结果",
      component: () =>
        import(
          /* webpackChunkName: "medicalresult" */ "./views/MedicalResult.vue"
        )
    },
    {
      path: "/medicalrecord",
      name: "填写记录",
      component: () =>
        import(
          /* webpackChunkName: "medicalrecord" */ "./views/MedicalRecord.vue"
        )
    },
    {
      path: "/oldman/index",
      name: "老年人中医健康管理服务",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/oldMan/index.vue")
    },
    {
      path: "/oldman/questions",
      name: "评估问卷",
      component: () =>
        import(
          /* webpackChunkName: "questions" */ "./views/oldMan/questions.vue"
        )
    },
    {
      path: "/oldman/result",
      name: "评估结果",
      component: () =>
        import(/* webpackChunkName: "questions" */ "./views/oldMan/result.vue")
    },
    {
      path: "/oldman/fill",
      name: "填写",
      component: () =>
        import(/* webpackChunkName: "questions" */ "./views/oldMan/fill.vue")
    }
  ]
});
