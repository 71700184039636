<template>
  <div id="app">
    <keep-alive include="medicaltest">
      <router-view :key="$route.fullPath" />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
    }
  },
  mounted() {
    console.log('app')
  },

}
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;

  }
  html,body {
    height: 100%;
  }
#app {
  font-family: -apple-system,SF UI Display,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,WenQuanYi Micro Hei,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size:24px;
  height: 100%;
  .scene {
    width: 100%;
    height: 7rem;
    position: relative;
    overflow: hidden;
    h1 {
      font-size:.3rem;
      margin-top: .6rem;
      color:rgba(255,255,255,1);
      line-height:.42rem;
      font-weight: 500;
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  .scrollbar {
     position: fixed;
     width: .75rem;
     height: 5rem;
     right: 20px;
     top: 1.5rem;
     z-index: 1000;
     background: #fff;
     border:.01rem solid rgba(235,235,235,.7);
     border-radius: 4px;
     li {
       width: .65rem;
       margin: 0 auto;
       list-style: none;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       border-bottom: .01rem solid rgba(235,235,235,.7);
       height: 1rem;
       cursor: pointer;
       &:last-child {
         border-bottom: none;
       }
       i {
         width: .48rem;
         height: .46rem;
         display: inline-block;
         &.s1 {
           background: url('./assets/img/s1.png') no-repeat;
           background-size: 100% 100%;
         }
         &.s2 {
           background: url('./assets/img/s2.png') no-repeat;
           background-size: 100% 100%;
         }
         &.s3 {
           background: url('./assets/img/s3.png') no-repeat;
           background-size: 100% 100%;
         }
         &.s4 {
           background: url('./assets/img/s4.png') no-repeat;
           background-size: 100% 100%;
         }
         &.s5 {
           background: url('./assets/img/s6.png') no-repeat;
           background-size: 100% 100%;
         }
       }
       span {
         font-size:.12rem;
         font-weight:400;
         color:rgba(208,208,208,1);
         margin-top: .15rem;
       }
       &.active {
         i {
           width: .47rem;
           height: .53rem;
         }
         span {
           color: #00C6AE;
         }
         .s1 {
           background: url('./assets/img/s1_1.png') no-repeat;
           background-size: 100% 100%;
         }
         .s2 {
           background: url('./assets/img/s2_1.png') no-repeat;
           background-size: 100% 100%;
         }
         .s3 {
           background: url('./assets/img/s3_1.png') no-repeat;
           background-size: 100% 100%;
         }
         .s4 {
           background: url('./assets/img/s4_1.png') no-repeat;
           background-size: 100% 100%;
         }
         .s5 {
           background: url('./assets/img/s6_1.png') no-repeat;
           background-size: 100% 100%;
         }
       }
     }
  }
  .goTop {
    width: .78rem;
    height: .78rem;
    background:#fff;
    //box-shadow:0px 1px 2px 0px rgba(0,0,0,0.5);
    position: fixed;
    border:.01rem solid rgba(235,235,235,.7);
    border-radius: 50%;
    right: 20px;
    top: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      width: .34rem;
      height:.47rem;
      background: url('./assets/img/top.png') no-repeat;
      background-size: 100% 100%;
      display: inline-block;
    }
  }
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
i.arrow {
  width: .19rem;
  height: .33rem;
  background: url('./assets/img/medical/arrow.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: .25rem;
  top: 0.34rem;
}
</style>

