<template>
  <div class="cooperate">
    <h1>合作案例</h1>
    <swiper :options="swiperOption">
      <swiper-slide>
        <div class="img1"></div>
        <div class="content content1">
            <h2>九州通智慧养老</h2>
            <p>利用物联网、云计算、大数据、 智能硬件等新一代信息技术产品形成的智 慧健康养老生态，能够实现个人、家庭、社区、机构与健康养老资源的有效对接和优化配置，推动健康养老服务智慧化升级，提升健康养老服务质量效率水平</p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="img2"></div>
        <div class="content content2">
          <h2>万家云医互联网医院</h2>
          <p>以电子病历为核心，通过信息技术互联网医疗信息的数据采集、处理、存储、传输和共享,实现病人信息数字化、医疗过程数字化、管理流程数字化、医疗服务数字化、信息交互数字化。</p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="img3"></div>
        <div class="content content3">
          <h2>武昌家医服务</h2>
          <p>赋能基层卫生，通过智能物联网设备，开展健康建档、家医签约、健康随访等工作。现场测量的血压、血糖、血氧、心电、体温数据也可以实时通过蓝牙传输到公卫系统，充实病人健康档案数据，提高数据收集及应用效率</p>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>

  </div>
</template>

<script>
  import 'swiper/dist/css/swiper.css'
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
    export default {
        name: "Cooperate",
        components: {
          swiper,
          swiperSlide
        },
        data() {
          return {
            swiperOption: {
              slidesPerView: 'auto',
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }
            }
          }
        }
    }
</script>

<style  lang="scss">
  @media screen and (max-width: 1920px) {
    .cooperate .swiper-container {
      width: 1150px!important;
    }
    .cooperate {
      height: 630px;
    }
  }
  @media screen and (max-width: 1366px) {
    .cooperate .swiper-container {
      width: 950px!important;
    }
    .cooperate {
      height: 8rem;
    }
  }
  .cooperate {
    width:100%;
    background:rgba(235,235,235,.29);
    overflow:hidden;
    h1 {
      font-family:PingFangSC;
      font-weight:500;
      color:rgba(89,89,89,1);
      line-height:.42rem;
      font-size:.3rem;
      margin-top: .5rem;
    }


    .swiper-container {
      width: 1000px;
      height: 370px;
      margin-top: .5rem;
      .swiper-wrapper {
        width: 100%;
        height: 100%;
        .swiper-slide {
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 2px 8px 0px rgba(229, 229, 229, 1);
          border-radius: .26rem;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          .img1 {
            width:128px;
            height:230px;
            background: url('../assets/img/img-2.png') no-repeat;
            background-size: 100% 100%;
          }
          .content {
            h2 {
              font-size: .22rem;
              color:rgba(89,89,89,1);
              line-height: .3rem;
              text-align: left;
            }
            p {
              font-size:.18rem;
              font-weight:400;
              color:rgba(99,99,99,1);
              line-height:.25rem;
              text-align: left;
              margin-top: .3rem;
            }
          }
          .content1 {
            width: 5.68rem;
            margin-left: .8rem;
            h2 {
              &:before {
                width: .6rem;
                height: .6rem;
                background: url('../assets/img/logo1.png') no-repeat;
                background-size: 100% 100%;
                content: '';
                display: inline-block;
                vertical-align: middle;
                margin-right: .3rem;
              }
            }
          }
          .content2 {
            width: 4.47rem;
            margin-left: .5rem;
            h2 {
              &:before {
                width: .62rem;
                height: .62rem;
                background: url('../assets/img/logo2.png') no-repeat;
                background-size: 100% 100%;
                content: '';
                display: inline-block;
                vertical-align: middle;
                margin-right: .3rem;
              }
            }
          }
          .content3 {
            width: 5rem;
            margin-left: .5rem;
            h2 {
              &:before {
                width: 2.19rem;
                height: .73rem;
                background: url('../assets/img/logo3.png') no-repeat;
                background-size: 100% 100%;
                content: '';
                display: inline-block;
                vertical-align: middle;
                margin-right: .1rem;
              }
            }
          }
          .img2 {
            width:277px;
            height:171px;
            background: url('../assets/img/img-3.png') no-repeat;
            background-size: 100% 100%;
          }
          .img3 {
            width:285px;
            height:214px;
            background: url('../assets/img/img-1.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
</style>
