<template>
  <div class="home">
    <LayoutHeader></LayoutHeader>
    <Intro ref="s0"></Intro>
    <Scene ref="s1"></Scene>
    <Cooperate ref="s2"></Cooperate>
    <Solution ref="s3"></Solution>
    <Access ref="s4"></Access>
    <LayoutFooter></LayoutFooter>
    <ul class="scrollbar"
        v-show="scrollShow">
      <!--      {{activeIndex}}-->
      <li :class="activeIndex==0 && 'active'"
          @click="goFloor(0)">
        <i class="s1"></i>

      </li>
      <li :class="activeIndex==1 && 'active'"
          @click="goFloor(1)">
        <i class="s2"></i>

      </li>
      <li :class="activeIndex==2 && 'active'"
          @click="goFloor(2)">
        <i class="s3"></i>

      </li>
      <li :class="activeIndex==3 && 'active'"
          @click="goFloor(3)">
        <i class="s4"></i>

      </li>
      <li :class="activeIndex==4 && 'active'"
          @click="goFloor(4)">
        <i class="s5"></i>

      </li>
    </ul>
    <div class="goTop"
         v-show="scrollShow"
         @click="goTop()"><i></i></div>
  </div>
</template>

<script>

import LayoutHeader from '@src/components/LayoutHeader'
import LayoutFooter from '@src/components/LayoutFooter'
import Intro from '@src/components/Intro'
import Cooperate from '@src/components/Cooperate'
import Solution from '@src/components/Solution'
import Access from '@src/components/Access'
import Scene from '@src/components/Scene'

export default {
  name: 'home',
  components: {
    LayoutHeader,
    LayoutFooter,
    Intro,
    Cooperate,
    Solution,
    Access,
    Scene
  },
  data () {
    return {
      scrollShow: false,
      activeIndex: -1
    }
  },
  mounted () {
    window.onscroll = () => {
      let scrollTop = window.scrollY;
      for (let i = 0; i < 5; i++) {
        if (scrollTop >= this.$refs['s' + i].$el.offsetTop) {
          this.activeIndex = i;
          this.scrollShow = true;
        }
      }
      if (scrollTop < this.$refs['s0'].$el.offsetTop) {
        this.activeIndex = -1;
        this.scrollShow = false;
      }
    }
  },
  methods: {
    scrollAnimation (currentY, targetY) {
      // 获取当前位置方法
      // const currentY = document.documentElement.scrollTop || document.body.scrollTop

      // 计算需要移动的距离
      let needScrollTop = targetY - currentY
      let _currentY = currentY
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10)
        _currentY += dist
        window.scrollTo(_currentY, currentY)
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY)
        } else {
          window.scrollTo(_currentY, targetY)
        }
      }, 1)
    },
    goFloor (index) {
      let top = this.$refs['s' + index].$el.offsetTop;
      //window.scrollTo(0,top);
      const currentY = document.documentElement.scrollTop || document.body.scrollTop
      this.scrollAnimation(currentY, top)
    },
    goTop () {
      const currentY = document.documentElement.scrollTop || document.body.scrollTop
      this.scrollAnimation(currentY, 0)
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: -apple-system, SF UI Display, Arial, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 24px;
  .scene {
    width: 100%;
    height: 7rem;
    position: relative;
    overflow: hidden;
    h1 {
      font-size: 0.3rem;
      margin-top: 0.6rem;
      color: rgba(255, 255, 255, 1);
      line-height: 0.42rem;
      font-weight: 500;
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  .scrollbar {
    position: fixed;
    width: 0.75rem;
    height: 5rem;
    right: 20px;
    top: 1.5rem;
    z-index: 1000;
    background: #fff;
    border: 0.01rem solid rgba(235, 235, 235, 0.7);
    border-radius: 4px;
    li {
      width: 0.65rem;
      margin: 0 auto;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 0.01rem solid rgba(235, 235, 235, 0.7);
      height: 1rem;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      i {
        width: 0.48rem;
        height: 0.46rem;
        display: inline-block;
        &.s1 {
          background: url('../assets/img/s1.png') no-repeat;
          background-size: 100% 100%;
        }
        &.s2 {
          background: url('../assets/img/s2.png') no-repeat;
          background-size: 100% 100%;
        }
        &.s3 {
          background: url('../assets/img/s3.png') no-repeat;
          background-size: 100% 100%;
        }
        &.s4 {
          background: url('../assets/img/s4.png') no-repeat;
          background-size: 100% 100%;
        }
        &.s5 {
          background: url('../assets/img/s6.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      span {
        font-size: 0.12rem;
        font-weight: 400;
        color: rgba(208, 208, 208, 1);
        margin-top: 0.15rem;
      }
      &.active {
        i {
          width: 0.47rem;
          height: 0.53rem;
        }
        span {
          color: #00c6ae;
        }
        .s1 {
          background: url('../assets/img/s1_1.png') no-repeat;
          background-size: 100% 100%;
        }
        .s2 {
          background: url('../assets/img/s2_1.png') no-repeat;
          background-size: 100% 100%;
        }
        .s3 {
          background: url('../assets/img/s3_1.png') no-repeat;
          background-size: 100% 100%;
        }
        .s4 {
          background: url('../assets/img/s4_1.png') no-repeat;
          background-size: 100% 100%;
        }
        .s5 {
          background: url('../assets/img/s6_1.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .goTop {
    width: 0.78rem;
    height: 0.78rem;
    background: #fff;
    //box-shadow:0px 1px 2px 0px rgba(0,0,0,0.5);
    position: fixed;
    border: 0.01rem solid rgba(235, 235, 235, 0.7);
    border-radius: 50%;
    right: 20px;
    top: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      width: 0.34rem;
      height: 0.47rem;
      background: url('../assets/img/top.png') no-repeat;
      background-size: 100% 100%;
      display: inline-block;
    }
  }
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
