<template>
    <div class="intro">
        <h1>平台介绍</h1>
        <div class="c">
          <div class="wrap">
            <div class="s" v-show="introStats[0][0]">
              <div class="img img1" @mouseover="hoverIntro(0)"></div>
              <p>物联智能设备</p>
            </div>
            <transition name="fade">
            <div class="s1"  @mouseleave="leaveIntro(0)" v-show="introStats[0][1]">
               <h1>物联智能设备</h1>
               <p>提供安全可靠的设备连接通信能力，帮助客户将海量设备数据采集上云，并支持调用API，实现实时数据监控。 另外可提供个性化的SDK，帮助客户快速集成</p>
             </div>
            </transition>
          </div>
          <div class="wrap">
            <div class="s" v-show="introStats[1][0]">
              <div class="img img2"  @mouseover="hoverIntro(1)"></div>
              <p>多维健康档案</p>
            </div>
          <transition name="fade">
          <div class="s1"  @mouseleave="leaveIntro(1)" v-show="introStats[1][1]">
            <h1>多维健康档案</h1>
            <p>基于物联网技术融合智能硬件，对接多渠道多系统健康数据，涵盖医疗健康、医疗康复、运动健康、生活习惯行为分析等多维数据，通过智能云算法及大数据分析，描绘“健康画像”，预测“健康走势”</p>
          </div>
          </transition>
          </div>
            <div class="wrap">
            <div class="s" v-show="introStats[2][0]">
              <div class="img img3"  @mouseover="hoverIntro(2)"></div>
              <p>健康分析引擎</p>
            </div>
            <transition name="fade">
            <div class="s1"  @mouseleave="leaveIntro(2)" v-show="introStats[2][1]">
            <h1>健康分析引擎</h1>
            <p>据智能算法库、规则与相应知识库， 对用户各类健康数据进行自动化计算与分析，并运用国家居民电子健康档案、基本医疗 与健康体检中常用的500多种常见健康信息，融合了万余种逻辑算法，为每个用户的健康状态提供有针对性的健康报告与健康干预方案</p>
          </div>
            </transition>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
      name: "Intro.vue",
      data() {
        return {
          introStats: [ [true,false], [true,false], [true,false]]
        }
      },
      methods:{
        hoverIntro(index){
          this.$set(this.introStats, index, [false, true])
        },
        leaveIntro(index) {
          //this.hello = 333;
          this.$set(this.introStats, index, [true, false])
        }
      }
    }
</script>

<style  lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .intro {
    width: 100%;
    height:7rem;
    background:rgba(235,235,235,.29);
    overflow: hidden;
    h1 {
      font-size:.3rem;
      font-family:PingFangSC;
      font-weight:500;
      color:rgba(89,89,89,1);
      line-height: .42rem;
      margin-top: .6rem;
    }
    .c  {
       width: 14rem;
       display: flex;
       margin: .5rem auto 0;
      justify-content: space-around;
      .wrap {
        width: 3.8rem;
        height: 4.6rem;
        position: relative;
        .s, .s1 {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: #fff;
          border-radius: .27rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .img {
            width: 1.96rem;
            height: 1.96rem;
            display: block;
          }

          .img1 {
            background: url('../assets/img/intro1.png') no-repeat;
            background-size: 100% 100%;
          }

          .img2 {
            background: url('../assets/img/intro2.png') no-repeat;
            background-size: 100% 100%;
          }

          .img3 {
            background: url('../assets/img/intro3.png') no-repeat;
            background-size: 100% 100%;
          }

          p {
            font-size: .22rem;
            font-family: PingFangSC;
            font-weight: 500;
            color: rgba(0, 198, 174, 1);
            margin-top: .5rem;
          }
        }

        .s1 {
          background: rgba(0, 198, 174, 1);
          color: #fff;

          h1 {
            font-size: .22rem;
            font-weight: 500;
            color: #fff;
            margin-top: 0;
            //margin-top: .2rem;
          }

          p {
            color: #fff;
            font-size: .18rem;
            box-sizing: border-box;
            padding: 0 .3rem;
            line-height: .28rem;
            text-align: left;
          }
        }
      }
    }

  }
</style>
